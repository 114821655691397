const getIsReadyToShip = (esd: string): boolean => {
  if (!esd) {
    return false;
  }

  const currentDate = new Date();
  const esdDate = new Date(esd);

  const busDaysToAdd = 2;

  for (let i = 1; i <= busDaysToAdd; i += 1) {
    currentDate.setDate(currentDate.getDate() + 1);
    if (currentDate.getDay() === 6) {
      currentDate.setDate(currentDate.getDate() + 2);
    } else if (currentDate.getDay() === 0) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  return currentDate > esdDate;
};

export default getIsReadyToShip;
